import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Legals from "../components/legals/Legals";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function LegalsPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "Legals"]}
          location={location}
          metaDescription="On this page you will find Jawg's legal notices."
          title="Legals"
        />
        <Page>
          <Legals />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

LegalsPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default LegalsPage;
